document.addEventListener("DOMContentLoaded", function() {
   
});

var hero = document.querySelector('#hero');

if(hero != null){
    var heroButton = hero.querySelector('button');
    heroButton.addEventListener('click', function(event){
        smoothScroll(hero.id, -hero.offsetHeight+navbar.offsetHeight);
    });
}


