document.addEventListener("DOMContentLoaded", function () {
    var sliders = document.querySelectorAll(".brands-slider");
    sliders.forEach(function (slider) {
        tns({
            container: slider,
            speed: 300,
            slideBy: "page",
            items: 2,
            autoplay: true,
            autoplayButton: false,
            autoplayButtonOutput: false,
            autoplayTimeout: 3500,
            autoplayText: false,
            nav: false,
            swipeAngle: false,
            controls: true,
            controlsContainer: document.querySelector(
                ".brand-slider__controls"
            ),
            responsive: {
                575: {
                    items: 3,
                },
                767: {
                    items: 4,
                },
                991: {
                    items: 4,
                },
                1199: {
                    items: 4,
                },
                1399: {
                    items: 5,
                },
                1400: {
                    items: 5,
                },
            },
        });
    });
});
