document.addEventListener("DOMContentLoaded", function() {
    
});

let offCanvas = document.querySelector('.offcanvas');
let hamburger = document.querySelector('.hamburger');

offCanvas.addEventListener('show.bs.offcanvas', (e) => {
    hamburger.classList.add('is-active');
    hamburger.classList.remove('collapsed');
});

offCanvas.addEventListener('hide.bs.offcanvas', (e) => {
    hamburger.classList.remove('is-active');
    hamburger.classList.add('collapsed');
});

